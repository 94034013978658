import _ from 'lodash'
import {
  promiseApplied,
  runInContext,
  waitForChangesApplied,
} from '../privates/util'
import {resolveOption} from '../../../utils/utils'
import refComponents from './refComponents/refComponents'

function addComponent(
  documentServices,
  appData,
  token,
  {componentDefinition, pageRef, customId, optionalIndex} = {}
) {
  return new Promise((resolve, reject) => {
    if (!_.has(componentDefinition, 'componentType')) {
      reject(new Error('componentDefinition must contain componentType'))
    }

    const componentStructure = documentServices.components.buildDefaultComponentStructure(
      componentDefinition.componentType
    )
    _.merge(componentStructure, componentDefinition)
    const compRef = runInContext(
      appData.appDefinitionId,
      documentServices,
      () =>
        documentServices.components.add(
          pageRef,
          componentStructure,
          customId,
          optionalIndex
        )
    )
    waitForChangesApplied(documentServices, () => resolve(compRef))
  })
}

function addAndAdjustLayout(
  documentServices,
  appData,
  token,
  {componentDefinition, pageRef, customId, optionalIndex} = {}
) {
  return new Promise((resolve, reject) => {
    if (!_.has(componentDefinition, 'componentType')) {
      reject(new Error('componentDefinition must contain componentType'))
    }

    const componentStructure = documentServices.components.buildDefaultComponentStructure(
      componentDefinition.componentType
    )
    _.merge(componentStructure, componentDefinition)
    const compRef = runInContext(
      appData.appDefinitionId,
      documentServices,
      () =>
        documentServices.components.addAndAdjustLayout(
          pageRef,
          componentStructure,
          customId,
          optionalIndex
        )
    )
    waitForChangesApplied(documentServices, () => resolve(compRef))
  })
}

function removeComponent(documentServices, appData, token, {componentRef}) {
  if (
    documentServices.viewMode.get() ===
    documentServices.viewMode.VIEW_MODES.MOBILE
  ) {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.mobile.hiddenComponents.hide(componentRef)
    )
  } else {
    runInContext(appData.appDefinitionId, documentServices, () =>
      documentServices.components.remove(componentRef)
    )
  }
}

function getAllComponents(documentServices) {
  return documentServices.components.getAllComponents()
}

function getProps(documentServices, appData, token, {componentRef}) {
  return documentServices.components.properties.get(componentRef)
}

function updateProps(documentServices, appData, token, {componentRef, props}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.properties.update(componentRef, props)
  )
}

function getPage(documentServices, appData, token, {componentRef}) {
  return documentServices.components.getPage(componentRef)
}

function getById(documentServices, appData, token, {id}) {
  return documentServices.components.get.byId(id)
}

function getData(documentServices, appData, token, {componentRef}) {
  return documentServices.components.data.get(componentRef)
}

function getAncestors(documentServices, appData, token, {componentRef}) {
  return documentServices.components.getAncestors(componentRef)
}

function getType(documentServices, appData, token, {componentRef}) {
  return documentServices.components.getType(componentRef)
}

function updateData(documentServices, appData, token, {componentRef, data}) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.data.update(componentRef, data)
  )
}

function updateDataInLang(
  documentServices,
  appData,
  token,
  {componentRef, data, languageCode}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.data.updateInLang(
      componentRef,
      data,
      languageCode
    )
  )
}

function getStyle(documentServices, appData, token, {componentRef}) {
  return documentServices.components.style.get(componentRef)
}

function updateCustomStyle(
  documentServices,
  appData,
  token,
  {componentRef, style}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.style.setCustom(componentRef, null, style)
  )
}

function updateFullStyle(
  documentServices,
  appData,
  token,
  {componentRef, style}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.style.update(componentRef, style)
  )
}

function updateStylableStyle(
  documentServices,
  appData,
  token,
  {componentRef, style}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.stylable.update(componentRef, style)
  )
}

function getDesign(documentServices, appData, token, {componentRef}) {
  return documentServices.components.design.get(componentRef)
}

// there is mismatch from the ts, requires 'design' but uses 'designItem'
// use whatever received not to break API or types
function updateDesign(
  documentServices,
  appData,
  token,
  {componentRef, designItem, design, retainCharas}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.design.update(
      componentRef,
      design || designItem,
      retainCharas
    )
  )
}

function getComponentDataByProps(
  documentServices,
  appData,
  token,
  {componentRefs, properties = [], applicationId}
) {
  const componentRefsAsArray = _.compact(
    _.isArray(componentRefs) ? componentRefs : [componentRefs]
  )
  return _.transform(
    componentRefsAsArray,
    (result, componentRef) => {
      const resObj = {componentRef}
      const shouldIgnoreChildren = !properties.includes('components')
      const serializedComponent = documentServices.components.serialize(
        componentRef,
        null,
        shouldIgnoreChildren
      )
      _.assign(resObj, _.pick(serializedComponent, properties))
      if (_.includes(properties, 'sdkType')) {
        resObj.sdkType = documentServices.wixCode.getCompSdkType(componentRef)
      }
      if (_.includes(properties, 'connections')) {
        resObj.connections = documentServices.platform.controllers.connections.get(
          componentRef
        )
      }
      if (_.includes(properties, 'style')) {
        resObj.style = documentServices.components.style.get(componentRef)
      }
      if (_.includes(properties, 'role')) {
        const connections = documentServices.platform.controllers.connections.get(
          componentRef
        )
        const appId = resolveOption(appData, {applicationId}, 'applicationId', {
          isRequired: true,
        })
        const controllerRef = _.find(
          connections,
          (connection) =>
            _.get(
              documentServices.components.data.get(connection.controllerRef),
              'applicationId'
            ) === appId
        )
        resObj.role = _.get(controllerRef, 'role')
      }
      result.push(resObj)
    },
    []
  )
}

function getLayout(documentServices, appData, token, {componentRef}) {
  return documentServices.components.layout.get(componentRef)
}

function showComponentOnlyOnPagesGroup(
  documentServices,
  appData,
  token,
  {componentPointer, componentRef = componentPointer, pagesGroupPointer}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.modes.showComponentOnlyOnPagesGroup(
      componentRef,
      pagesGroupPointer
    )
  )
}

function activateComponentMode(
  documentServices,
  appData,
  token,
  {componentRef, modeId}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.modes.activateComponentMode(
      componentRef,
      modeId
    )
  )
}

function getModes(documentServices, appData, token, {componentRef}) {
  return documentServices.components.modes.getModes(componentRef)
}

function getRuntimeState(documentServices, appData, token, {componentRef}) {
  return documentServices.components.behaviors.getRuntimeState(componentRef)
}

function updateBehavior(
  documentServices,
  appData,
  token,
  {componentRef, behavior}
) {
  return documentServices.components.behaviors.update(componentRef, behavior)
}

function getBehaviors(documentServices, appData, token, {componentRef}) {
  return documentServices.components.behaviors.get(componentRef)
}

function removeBehavior(
  documentServices,
  appData,
  token,
  {componentRef, behaviorName, actionName}
) {
  return documentServices.components.behaviors.remove(
    componentRef,
    behaviorName,
    actionName
  )
}

function executeRuntimeBehavior(
  documentServices,
  appData,
  token,
  {componentRef, behaviorName, behaviorParams}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.behaviors.execute(
      componentRef,
      behaviorName,
      behaviorParams
    )
  )
  return promiseApplied(documentServices)
}

function applyCurrentToAllModes(
  documentServices,
  appData,
  token,
  {componentRef}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.modes.applyCurrentToAllModes(componentRef)
  )
}

function getChildren(
  documentServices,
  appData,
  token,
  {componentRef, recursive = false, fromDocument = false}
) {
  const getChildrenFn = fromDocument
    ? documentServices.components.getChildrenFromFull
    : documentServices.components.getChildren
  return getChildrenFn(componentRef, recursive)
}

function updateLayout(
  documentServices,
  appData,
  token,
  {componentRef, layout}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.layout.update(componentRef, layout)
  )
}

function serialize(
  documentServices,
  appData,
  token,
  {componentRef, maintainIdentifiers = false}
) {
  return documentServices.components.serialize(
    componentRef,
    null,
    false,
    maintainIdentifiers
  )
}

function migrate(
  documentServices,
  appData,
  token,
  {componentRef, componentDefinition}
) {
  return documentServices.components.migrate(componentRef, componentDefinition)
}

function moveToIndex(documentServices, appData, token, {componentRef, index}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.components.arrangement.moveToIndex(componentRef, index)
  )
}

function getIndex(documentServices, appData, token, {componentRef}) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  return documentServices.components.arrangement.getCompIndex(componentRef)
}

function isFullWidth(documentServices, appData, token, {componentRef}) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  return documentServices.components.is.fullWidth(componentRef)
}

function setFullWidth(
  documentServices,
  appData,
  token,
  {componentRef, fullWidth, margins}
) {
  if (!componentRef) {
    throw new Error('options must include componentRef property')
  }

  if (typeof fullWidth !== 'boolean') {
    throw new Error('options must include fullWidth boolean property')
  }

  return new Promise((resolve) => {
    if (fullWidth) {
      const {left, right} = margins || {}
      const dockParams = {
        // vw units must be specified, otherwise the component will not be
        // considered "full-width" (i.e. `isFullWidth` will return `false`)
        left: Object.assign({vw: 0}, left),
        right: Object.assign({vw: 0}, right),
      }
      documentServices.components.layout.setDock(componentRef, dockParams)
    } else {
      documentServices.components.layout.unDock(componentRef)
    }
    waitForChangesApplied(documentServices, resolve)
  })
}

function buildDefaultComponentStructure(
  documentServices,
  appData,
  token,
  {componentType}
) {
  return documentServices.components.buildDefaultComponentStructure(
    componentType
  )
}

export default {
  add: addComponent,
  addAndAdjustLayout,
  remove: removeComponent,
  get: getComponentDataByProps,
  serialize,
  migrate,
  getAllComponents,
  getPage,
  getById,
  getChildren,
  getAncestors,
  getType,
  isFullWidth,
  setFullWidth,
  buildDefaultComponentStructure,
  behaviors: {
    getRuntimeState,
    execute: executeRuntimeBehavior,
    get: getBehaviors,
    update: updateBehavior,
    remove: removeBehavior,
  },
  properties: {
    get: getProps,
    update: updateProps,
  },
  data: {
    get: getData,
    update: updateData,
    updateInLanguage: updateDataInLang,
  },
  layout: {
    get: getLayout,
    update: updateLayout,
  },
  style: {
    get: getStyle,
    update: updateCustomStyle,
    updateFull: updateFullStyle,
  },
  stylable: {
    update: updateStylableStyle,
  },
  design: {
    get: getDesign,
    update: updateDesign,
  },
  modes: {
    showComponentOnlyOnPagesGroup,
    activateComponentMode,
    applyCurrentToAllModes,
    getModes,
  },
  arrangement: {
    moveToIndex,
    getIndex,
  },
  refComponents,
}
