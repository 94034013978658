import _ from 'lodash'
import {promiseApplied, runInContext} from '../privates/util'
import {resolveOption} from '../../../utils/utils'

const CONTROLLER_COMPONENT_TYPE = 'platform.components.AppController'
const APP_WIDGET_COMPONENT_TYPE = 'platform.components.AppWidget'
const MASTER_PAGE_ID = 'masterPage'
const GLOBAL_MASTER_PAGE_CONTAINERS = {
  SITE_HEADER: true,
  SITE_FOOTER: true,
}

export function isControllerType(compType) {
  return (
    compType === CONTROLLER_COMPONENT_TYPE ||
    compType === APP_WIDGET_COMPONENT_TYPE
  )
}

function canConnectComponentToController(
  documentServices,
  componentRef,
  controllerRef
) {
  return (
    documentServices.components.getType(controllerRef) !==
      APP_WIDGET_COMPONENT_TYPE ||
    documentServices.components.isDescendantOfComp(componentRef, controllerRef)
  )
}

const getTopLevelContainerId = (documentServices, compRef) =>
  _(compRef)
    .thru(documentServices.components.getAncestors)
    .reverse()
    .tail()
    .map('id')
    .head()

function isControllerAndComponentOnSameScope(
  documentServices,
  componentPageId,
  componentTopLevelContainerId,
  controllerRef
) {
  const controllerPage = documentServices.components.getPage(controllerRef)

  if (controllerPage.id === MASTER_PAGE_ID) {
    const controllerTopLevelContainerId = getTopLevelContainerId(
      documentServices,
      controllerRef
    )

    //controller is directly on the master page (SOAP), or its on the Header / Footer
    if (
      !controllerTopLevelContainerId ||
      GLOBAL_MASTER_PAGE_CONTAINERS[controllerTopLevelContainerId]
    ) {
      return true
    }

    //Template controller in a shared block, check if the component is on same template shared block
    return componentTopLevelContainerId === controllerTopLevelContainerId
  }

  //Check if controller and component are on the same page
  return componentPageId === controllerPage.id
}

function getControllerComponentsInPage(
  documentServices,
  {appDefinitionId},
  pagePointer,
  includeTPAWidget,
  getFromFull
) {
  const pageId = pagePointer ? pagePointer.id : null

  // Controllers in masterPage shouldn't be listed for popup pages.
  // This filter is needed because of the behaviour of getAllComponents along with pop up pages, which returns masterPage components/controllers also.

  const filterMasterPageControllersFromPopUpPages = (compRef) => {
    const isPopUpPage =
      pageId && documentServices.pages.popupPages.isPopup(pageId)
    if (isPopUpPage) {
      const controllerPage = documentServices.components.getPage(compRef)
      return controllerPage.id !== 'masterPage'
    }
    return true
  }

  const getCompsMethod = getFromFull
    ? documentServices.components.getAllComponentsFromFull
    : documentServices.components.getAllComponents

  return getCompsMethod(pageId, (compRef) => {
    const compType = documentServices.components.getType(compRef)
    return (
      (isControllerType(compType) &&
        documentServices.components.data.get(compRef).applicationId ===
          appDefinitionId &&
        filterMasterPageControllersFromPopUpPages(compRef)) ||
      (includeTPAWidget &&
        documentServices.tpa.isTpaByCompType(compType) &&
        documentServices.components.data.get(compRef).appDefinitionId ===
          appDefinitionId &&
        filterMasterPageControllersFromPopUpPages(compRef))
    )
  })
}

function listControllersImpl(
  documentServices,
  context,
  pageRef,
  includeTPAWidget
) {
  const controllersArray = getControllerComponentsInPage(
    documentServices,
    context,
    pageRef,
    includeTPAWidget
  )
  const templateControllers = _(controllersArray)
    .map(documentServices.components.refComponents.getTemplateCompPointer)
    .compact()
    .keyBy('id')
    .value()

  return _(controllersArray)
    .reject(({id}) => templateControllers[id])
    .map((controllerRef) => ({controllerRef}))
    .value()
}

const listControllers = (
  documentServices,
  appData,
  token,
  {
    pageRef = documentServices.pages.getFocusedPage(),
    appDefinitionId,
    includeTPAWidget,
  } = {}
) => {
  const context = {
    appDefinitionId: resolveOption(
      appData,
      {appDefinitionId},
      'appDefinitionId',
      {isRequired: true}
    ),
  }

  return listControllersImpl(
    documentServices,
    context,
    pageRef,
    includeTPAWidget
  )
}

const listAllControllers = (
  documentServices,
  appData,
  token,
  {appDefinitionId, includeTPAWidget} = {}
) => {
  const context = {
    appDefinitionId: resolveOption(
      appData,
      {appDefinitionId},
      'appDefinitionId',
      {isRequired: true}
    ),
  }

  return listControllersImpl(documentServices, context, null, includeTPAWidget)
}

function listConnectableControllers(
  documentServices,
  appData,
  token,
  {componentRef, appDefinitionId, includeTPAWidget}
) {
  const compPagePointer = documentServices.components.getPage(componentRef)

  const contextData = {
    appDefinitionId: resolveOption(
      appData,
      {appDefinitionId},
      'appDefinitionId',
      {isRequired: true}
    ),
  }
  const allControllers = getControllerComponentsInPage(
    documentServices,
    contextData,
    compPagePointer,
    includeTPAWidget,
    true
  )
  const compContainerId = getTopLevelContainerId(documentServices, componentRef)

  return _(allControllers)
    .filter((controllerRef) =>
      isControllerAndComponentOnSameScope(
        documentServices,
        _.get(compPagePointer, 'id'),
        compContainerId,
        controllerRef
      )
    )
    .filter((controllerRef) =>
      canConnectComponentToController(
        documentServices,
        componentRef,
        controllerRef
      )
    )
    .map((controllerRef) => ({controllerRef}))
    .value()
}

function listConnections(documentServices, appData, token, {componentRef}) {
  const allConnections = documentServices.platform.controllers.connections.get(
    componentRef
  )
  return _.reject(allConnections, {type: 'WixCodeConnectionItem'})
}

function listConnectedComponents(
  documentServices,
  appData,
  token,
  {controllerRef}
) {
  return documentServices.platform.controllers.connections.getConnectedComponents(
    controllerRef
  )
}

function connect(
  documentServices,
  appData,
  token,
  {connectToRef, controllerRef, role, connectionConfig, isPrimary, subRole}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.controllers.connections.connect(
      connectToRef,
      controllerRef,
      role,
      connectionConfig,
      isPrimary,
      subRole
    )
  )
  return {connectToRef, controllerRef, role, subRole}
}

function disconnect(
  documentServices,
  appData,
  token,
  {connectToRef, controllerRef, role}
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.controllers.connections.disconnect(
      connectToRef,
      controllerRef,
      role
    )
  )
}

function getDataTPA(documentServices, {controllerRef, compData, scope}) {
  let config = {}
  documentServices.tpa.data.getPublicData(
    compData.applicationId,
    controllerRef,
    (data) => {
      if (data.error) {
        throw new Error(data.error)
      } else {
        config = data
      }
    }
  )
  const displayName = documentServices.platform.controllers.getName(
    controllerRef
  )
  return {
    type: compData.widgetId,
    config: config[scope],
    displayName,
  }
}

function getData(
  documentServices,
  appData,
  token,
  {controllerRef, scope = 'COMPONENT'}
) {
  const isTPA = documentServices.tpa.isTpaByCompType(
    documentServices.components.getType(controllerRef)
  )
  const compData = documentServices.components.data.get(controllerRef)
  if (isTPA) {
    return getDataTPA(documentServices, {controllerRef, compData, scope})
  }
  const config = documentServices.platform.controllers.settings.get(
    controllerRef
  )
  const type = _.get(compData, 'controllerType')
  const displayName = documentServices.platform.controllers.getName(
    controllerRef
  )
  return {
    type,
    config,
    displayName,
  }
}

function saveConfiguration(
  documentServices,
  appData,
  token,
  {controllerRef, config, scope = 'COMPONENT'}
) {
  const compType = documentServices.components.getType(controllerRef)
  const isTPA = documentServices.tpa.isTpaByCompType(compType)
  if (isTPA) {
    return new Promise((res, rej) => {
      return runInContext(appData.appDefinitionId, documentServices, () =>
        documentServices.tpa.data.setMultiple(
          controllerRef,
          config,
          scope,
          (data) => {
            return data.error ? rej(new Error(data.error.message)) : res(data)
          }
        )
      )
    })
  }

  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.controllers.settings.set(controllerRef, config)
  )
}

function setDisplayName(
  documentServices,
  appData,
  token,
  {controllerRef, name}
) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.controllers.setName(controllerRef, name)
  )
}

function setState(documentServices, appData, token, {state}) {
  return runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.controllers.setState(state)
  )
}

function listControllerConnections(
  documentServices,
  appData,
  token,
  {controllerRef}
) {
  return documentServices.platform.controllers.connections.getControllerConnections(
    controllerRef
  )
}

function setExternalId(
  documentServices,
  appData,
  token,
  {controllerRef, externalId}
) {
  runInContext(appData.appDefinitionId, documentServices, () =>
    documentServices.platform.controllers.settings.setExternalId(
      controllerRef,
      externalId
    )
  )

  return promiseApplied(documentServices)
}

function getExternalId(documentServices, appData, token, {controllerRef}) {
  return documentServices.platform.controllers.settings.getExternalId(
    controllerRef
  )
}

function findAllByType(
  documentServices,
  appData,
  token,
  {controllerType, pageRef, appDefinitionId}
) {
  const controllers = listControllers(documentServices, appData, token, {
    pageRef: pageRef || null,
    appDefinitionId,
  })

  const controllersOfType = []

  for (const controller of controllers) {
    const controllerData = getData(documentServices, appData, token, controller)

    if (controllerData.type === controllerType) {
      controllersOfType.push(controller)
    }
  }

  return controllersOfType
}

export default {
  /**
   * @param {string} token - app token, not in use
   * @returns {Array<object>} result[i].controllerRef is a documentServices Ref to the controller
   */
  listControllers,

  /**
   * @param {string} token - app token, not in use
   * @returns {Array<object>} result[i].controllerRef is a documentServices Ref to the controller
   */
  listAllControllers,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options.componentRef the target component
   * @returns {Array<object>} result[i].controllerRef is a documentServices Ref to the controller
   */
  listConnectableControllers,

  /**
   * @deprecated Use listControllerConnections instead
   * @description This method is deprecated. Use listControllerConnections instead.
   * @param {string} token - app token, not in use
   * @param {object} options.controllerRef the target controller
   * @returns {Array<object>} array of connection objects which are associated with the given controller
   */
  getControllerConnections: listControllerConnections,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options.controllerRef the target controller
   * @returns {Array<object>} array of connection objects which are associated with the given controller
   */
  listControllerConnections,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.componentRef the target component
   * @returns {Array<object>} array of connection objects
   */
  listConnections,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.controllerRef the target controller
   * @returns {Array<object>} array of connected components
   */
  listConnectedComponents,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.connectToRef
   * @param {object} options.controllerRef
   * @param {string} options.role
   * @param {object} options.connectionConfig
   */
  connect,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.connectToRef
   * @param {object} options.controllerRef
   * @param {string} options.role
   */
  disconnect,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.controllerRef
   * @returns {object} the controller's settings
   */
  getData,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.controllerRef
   * @param {object} options.config
   * @returns {Promise} resolves upon completion
   */
  saveConfiguration,

  /**
   * @param {string} token - app token, not in use
   * @param {object} controllerRef
   * @param {string} controllerName
   * @returns {Promise} resolved upon completion
   */
  setDisplayName,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.state
   * @returns {Promise} resolves upon completion
   */
  setState,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.controllerRef
   * @param {object} options.externalId
   * @returns {Promise} resolves upon completion
   */
  setExternalId,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {object} options.controllerRef
   * @returns {object} the controller's external settings id
   */
  getExternalId,

  /**
   * @param {string} token - app token, not in use
   * @param {object} options
   * @param {string} options.controllerType - a type of a controller to find by
   * @param {object} options.pageRef - a page ref to find a controller on (if not provided, the method will search the controller on the site)
   * @param {string} options.appDefinitionId - an application ID the controller belongs to
   * @returns {object} the list of controllers of the type
   */
  findAllByType,
}
