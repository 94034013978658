function getCustomNoPermissionsPageId(documentServices /*, token*/) {
  return documentServices.siteMembers.getCustomNoPermissionsPageId()
}

function setCustomNoPermissionsPageId(
  documentServices,
  appData,
  token,
  pageId
) {
  return documentServices.siteMembers.setCustomNoPermissionsPageId(pageId)
}

export default {
  getCustomNoPermissionsPageId,
  setCustomNoPermissionsPageId,
}
